var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pvD_rv3MLo_8iRAtGvI5b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://22618d95b80e41b28bfa82001b6cc9d7@o55209.ingest.sentry.io/4503918245969920',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    denyUrls: [
      'https://accounts.google.com',
      'https://gtm.bilhandel.dk/g/collect',
      'https://pre.bilhandel.dk',
      'https://accounts.google.com/gsi/client',
      'https://connect.facebook.net',
      'https://client.prod.mplat-ppcprotect.com',
    ],
    ignoreErrors: [
      "Can't execute code from freed script",
      'SecurityError',
      'The operation is insecure.',
      'Failed to fetch',
      /Unexpected token '<'[.*]/,
      /Hydration failed[.*]/,
      /There was an error while hydrating[.*]/,
      'Hydration failed because the initial UI does not match what was rendered on the server.',
      'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
      'ga.getAll is not a function',
      "Unable to get property 'sqrt' of undefined or null reference",
      'V',
      'Object.keys called on non-object',
      "Cannot read properties of null (reading 'postMessage')",
      /Blocked a frame with origin[.*]/,
      'ga.getAll is not a function',
      "Unable to get property 'sqrt' of undefined or null reference",
      /Loading chunk[.*]/,
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Cancelled',
      'message: cancelled',
      'cancelled',
      /The operation is insecure[.*]/,
      'annulleret',
      'Load failed',
      'Error: There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
      /Aborted\(Error: WebAssembly[.*]/,
      /[native code]/,
      /Text content does[.*]/,
      'Text content does not match server-rendered HTML.',
      /Error: Text content[.*]/,
      'Error: Text content does not match server-rendered HTML.',
      /Error: Hydration[.*]/,
      'Error: Hydration failed because the initial UI does not match what was rendered on the server.',
      /Error: There was an error while hydrating[.*]/,
      /Error: Minified React error #425[.*]/,
      /Error: Minified React error #418[.*]/,
      /Minified React error #425[.*]/,
      /Minified React error #418[.*]/,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
