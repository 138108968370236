import { SearchPageAttribute } from '@/shared/lib-api';
import { Box } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import DefaultItemInput from '../defaults/default-item-input';
import { DefaultItemLabel } from '../defaults/default-item-label';
import DefaultItemInputSelect from '../defaults/selects/default-item-input-select';

interface PropsFromParent {
  subHeader?: string;
  subHeaderColor?: string;
  placeholder: string;
  prefix?: string;
  name: string;
  dataKey?: string;
  dataIndex?: number;
  type?: React.HTMLInputTypeAttribute;
  transformInputText?: boolean;
  submitOnBlur?: boolean;
  dropDownValues?: SearchPageAttribute[];
  disable?: boolean;
  onChange?: (
    value: string | SearchPageAttribute | SearchPageAttribute[],
  ) => void;
  showAsErrored?: boolean
}
const SubHeaderInputField: React.FC<PropsFromParent> = props => {
  const formik = useFormikContext();
  const [_, meta] = useField(props.name!);
  const onValueChanged = React.useCallback(
    (value: string | SearchPageAttribute | SearchPageAttribute[]) => {
      if (props.dataIndex !== undefined && props.dataKey !== undefined) {
        const formikValueToModify = formik.values[props.dataKey];
        const itemToModify = formikValueToModify[props.dataIndex];
        itemToModify[props.name] = value;
        formikValueToModify[props.dataIndex] = itemToModify;
        formik.setFieldValue(props.dataKey, formikValueToModify);
      } else {
        formik.setFieldValue(props.name, value);
      }
      if (props.onChange) {
        props.onChange(value);
      }
    },
    [formik, props],
  );

  const onChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValueChanged(event.target.value);
    },
    [onValueChanged],
  );

  const onBlur = React.useCallback(() => {
    if (props.submitOnBlur) {
      formik.submitForm();
    }
  }, [props.submitOnBlur, formik]);

  const onDropDownChanged = React.useCallback(
    (value: string | SearchPageAttribute | SearchPageAttribute[]) => {
      onValueChanged(value);

      onBlur();
    },
    [onValueChanged, onBlur],
  );

  const error = meta.touched && meta.error || (props.showAsErrored === true && " ");

  return (
    <Box width={'100%'}>
      <DefaultItemLabel header={props.subHeader} />
      {props.dropDownValues ? (
        <DefaultItemInputSelect
          values={props.dropDownValues}
          name={props.name}
          placeholder={props.placeholder}
          value={formik.values[props.name]}
          onChanged={onDropDownChanged}
        />
      ) : (
        <DefaultItemInput
          disable={props.disable}
          error={error}
          onBlur={onBlur}
          onChange={onChanged}
          placeholder={props.placeholder}
          prefix={props.prefix}
          type={props.type}
          value={
            formik && formik.values && props.name
              ? formik.values[props.name] === 0
                ? ''
                : props.transformInputText
                ? formik.values[props.name]?.toUpperCase()
                : formik.values[props.name]
              : undefined
          }
        />
      )}
    </Box>
  );
};

export default SubHeaderInputField;
