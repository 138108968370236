/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessedDataType,
    ProcessedDataTypeFromJSON,
    ProcessedDataTypeFromJSONTyped,
    ProcessedDataTypeToJSON,
} from './ProcessedDataType';

/**
 * 
 * @export
 * @interface PromptConfig
 */
export interface PromptConfig {
    /**
     * 
     * @type {string}
     * @memberof PromptConfig
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PromptConfig
     */
    sourceType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PromptConfig
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PromptConfig
     */
    prompt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromptConfig
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromptConfig
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromptConfig
     */
    producesTypeId?: string;
    /**
     * 
     * @type {ProcessedDataType}
     * @memberof PromptConfig
     */
    producesType?: ProcessedDataType;
    /**
     * 
     * @type {number}
     * @memberof PromptConfig
     */
    maxLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromptConfig
     */
    temparture?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromptConfig
     */
    finalPrompt?: boolean;
}

export function PromptConfigFromJSON(json: any): PromptConfig {
    return PromptConfigFromJSONTyped(json, false);
}

export function PromptConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sourceType': !exists(json, 'sourceType') ? undefined : json['sourceType'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'prompt': !exists(json, 'prompt') ? undefined : json['prompt'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'producesTypeId': !exists(json, 'producesTypeId') ? undefined : json['producesTypeId'],
        'producesType': !exists(json, 'producesType') ? undefined : ProcessedDataTypeFromJSON(json['producesType']),
        'maxLength': !exists(json, 'maxLength') ? undefined : json['maxLength'],
        'temparture': !exists(json, 'temparture') ? undefined : json['temparture'],
        'finalPrompt': !exists(json, 'finalPrompt') ? undefined : json['finalPrompt'],
    };
}

export function PromptConfigToJSON(value?: PromptConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceType': value.sourceType,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'prompt': value.prompt,
        'enabled': value.enabled,
        'title': value.title,
        'producesTypeId': value.producesTypeId,
        'producesType': ProcessedDataTypeToJSON(value.producesType),
        'maxLength': value.maxLength,
        'temparture': value.temparture,
        'finalPrompt': value.finalPrompt,
    };
}

