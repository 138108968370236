import { getClassifiedVipUrl } from '@/web/util/helpers/url-helpers';
import TagManager from 'react-gtm-module';
import { ClassifiedItem, LeadProductStatus, UserProfileType } from '../lib-api';
import Cookies from 'js-cookie';
import { GetMediumImage } from './helpers/image-helper';

export function getClientId() {
  if (typeof window === 'undefined') {
    return 'missing';
  }

  const gaCookie = Cookies.get('_ga');
  const clientId: string = gaCookie
    ? gaCookie.split('.').slice(-2).join('.')
    : getUUID();
  //console.log('clientId', clientId);
  return clientId;
}

const getUUID = () => {
  // create pseudo id
  // https://docs.growthbook.io/guide/GA4-google-analytics
  const COOKIE_NAME = 'gbuuid';

  // use the browsers crypto.randomUUID if set
  const genUUID = () => {
    if (window?.crypto?.randomUUID) return window.crypto.randomUUID();
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    );
  };

  // get the existing UUID from cookie if set, otherwise create one and store it in the cookie
  if (Cookies.get(COOKIE_NAME)) return Cookies.get(COOKIE_NAME);

  const uuid = genUUID();
  Cookies.set(COOKIE_NAME, uuid, { expires: 400 });
  return uuid;
};

export function TrackExperimentViewed(
  experiment_id: string,
  variation_id: string,
) {
  const eventData = {
    event_category: 'experiment',
    experiment_id: experiment_id,
    variation_id: variation_id,
  };
  TrackEvent('experiment_viewed', eventData);
}

export function TrackEvent(event: string, eventData?: any) {
  const dataLayer = { ...eventData, event: event };
  const dataLayerEvent = {
    dataLayer,
  };
  TagManager.dataLayer(dataLayerEvent);
}

export function TrackClassifiedItemEvent(event: string, item: ClassifiedItem) {
  const dataLayer = {
    event: event,
    dlv_classified_make: item.make,
    dlv_classified_model: item.model,
    dlv_classified_year: item.yearOfManufacture,
    dlv_classified_fueltype: item.fueltype,
    dlv_classified_mileage: item.mileage,
    dlv_classified_price: item.systemData.price,
    dlv_classified_category: item.systemData.category,

    dlv_classified_id: item.id,
    dlv_classified_transmission: item.transmission,
    dlv_classified_headline: item.headLine,
    dlv_classified_url: getClassifiedVipUrl(item.id, item.headLine),
    dlv_classified_color: item.color,
    dlv_classified_bodystyle: item.bodyStyle,
    dlv_classified_drivetrain: item.drivetrain,
    dlv_classified_isreduced:
      item.changeInMaxPrice !== undefined && item.changeInMaxPrice < 0,

    dlv_dealer_name:
      item.owner.profileType === UserProfileType.Company
        ? item.owner.name
        : 'privat sælger',

    dlv_dealer_id: item.owner.id,
    dlv_dealer_iscompany: item.owner.profileType === UserProfileType.Company,

    dlv_dealer_address: item.owner.address,
    dlv_dealer_postalcode: item.owner.zipCode,
    dlv_dealer_city: item.owner.city,
    dlv_dealer_type: item.owner.profileType,
    dlv_dealer_islead:
      (item.owner.leadProductStatus === undefined ||
        item.owner.leadProductStatus === LeadProductStatus.Active) &&
      item.owner.isLead,

    dlv_classified_image0url:
      item.images.length >= 1
        ? GetMediumImage(item.images[0].folderId, item.images[0].id)
        : '',
    dlv_classified_image1url:
      item.images.length >= 2
        ? GetMediumImage(item.images[1].folderId, item.images[1].id)
        : '',
    dlv_classified_image2url:
      item.images.length >= 3
        ? GetMediumImage(item.images[2].folderId, item.images[2].id)
        : '',
    dlv_classified_hasvideo: item.videos.length > 0
  };
  const dataLayerEvent = {
    dataLayer,
  };
  TagManager.dataLayer(dataLayerEvent);
}

export function SetConsent(
  ad_storage?: boolean,
  analytics_storage?: boolean,
  functionality_storage?: boolean,
  personalization_storage?: boolean,
  security_storage?: boolean,
) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'SetConsent',
      ad_storage:
        ad_storage === undefined ? 'unset' : ad_storage ? 'granted' : 'denied',
      analytics_storage:
        analytics_storage === undefined
          ? 'unset'
          : analytics_storage
          ? 'granted'
          : 'denied',
      functionality_storage:
        functionality_storage === undefined
          ? 'unset'
          : functionality_storage
          ? 'granted'
          : 'denied',
      personalization_storage:
        personalization_storage === undefined
          ? 'unset'
          : personalization_storage
          ? 'granted'
          : 'denied',
      security_storage:
        security_storage === undefined
          ? 'unset'
          : security_storage
          ? 'granted'
          : 'denied',
    },
  });
}

export class BuildInTrackEvents {
  static readonly OnClassifiedFavorited = 'on_classified_favorited';
  static readonly OnClassifiedUnFavorited = 'on_classified_unfavorited';
  static readonly OnClassifiedUnFavoritedAllInactive =
    'on_classified_unfavorited_all_inactive';
  static readonly OnClassifiedShared = 'on_classified_shared';

  static readonly OnUserCreated = 'on_user_created';
  static readonly OnUserLogin = 'on_user_login';

  static readonly OnSearchAgentCreated = 'on_searchagent_created';
  static readonly OnSearchAgentCreatedTopA =
    'on_searchagent_created_seosrp_top_a';

  static readonly OnSearchAgentCreatedTopB =
    'on_searchagent_created_seosrp_top_b';
  static readonly OnSearchAgentCreatedBottom =
    'on_searchagent_created_seosrp_bottom';
  static readonly OnSearchAgentDeleted = 'on_searchagent_deleted';
  static readonly OnSearchAgentModified = 'on_searchagent_modified';
  static readonly OnSearchAgentViewResult = 'on_searchagent_viewresult';

  static readonly OnClassifiedActive = 'on_classified_active';
  static readonly OnClassifiedInActive = 'on_classified_inactive';
  static readonly OnClassifiedDeleted = 'on_classified_deleted';
  static readonly OnClassifiedCreated = 'on_classified_created';
  static readonly OnClassifiedModified = 'on_classified_modified';
  static readonly OnClassifiedRelatedClicked = 'on_classified_related_clicked';
  static readonly OnClassifiedNordicEnergyClicked =
    'on_classified_nordic_energy_clicked';
  static readonly OnClassifiedFdmClicked = 'on_classified_fdm_clicked';

  static readonly OnDealerMessageSent = 'on_dealer_message_sent';
  static readonly OnDealerMessageSentChatbot = 'on_dealer_message_sent_chatbot';

  static readonly OnDreiversPhoneClick = 'dreivers_ring_op_klikket';
  static readonly OnDreiversEmailClick = 'dreivers_send_info_succes';

  static readonly OnDealerListCallClicked = 'on_dealer_list_call_clicked';
  static readonly OnDealerListCallMeClicked = 'on_dealer_list_callme_clicked';
  static readonly OnDealerListWriteClicked = 'on_dealer_list_write_clicked';

  static readonly OnSeoSrpHoverPricePerMileage =
    'on_seo_srp_hover_price_per_mileage';
  static readonly OnSeoSrpClickPricePerMileage =
    'on_seo_srp_click_price_per_mileage';

  static readonly OnClassifiedCleverClicked = 'on_classified_clever_clicked';
  static readonly OnCleverShown = 'clever_vist';
}
