import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface FormikModalButtonProps {
  text: string;
  loadingText?: string;
}

export const FormikModalButton: React.FC<FormikModalButtonProps> = (
  props: FormikModalButtonProps,
) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const onClick = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  return (
    <Button onClick={onClick} color={'secondary'} fullWidth={true}>
      {formik.isSubmitting && props.loadingText !== undefined
        ? t(props.loadingText)
        : t(props.text)}
    </Button>
  );
};
