import { useMemo } from 'react';
import {
  Configuration as BilhandelConfiguration,
} from '../lib-api/runtime';
import useMiddleware from './middleware';
import { SearchApi } from '../lib-api/apis/SearchApi';
import { SearchAgentApi } from '../lib-api/apis/SearchAgentApi';
import { DealerApi } from '../lib-api/apis/DealerApi';
import { AuthApi } from '../lib-api/apis/AuthApi';
import { LikeApi } from '../lib-api/apis/LikeApi';
import { ClassifiedApi } from '../lib-api/apis/ClassifiedApi';
import { GeoLocationApi } from '../lib-api/apis/GeoLocationApi';
import { ClassifiedCreationApi } from '../lib-api/apis/ClassifiedCreationApi';
import { ServiceApi } from '../lib-api/apis/ServiceApi';
import { CarpalApi } from '../lib-api/apis/CarpalApi';
import { ChatbotApi, SearchbotApi } from '../lib-api';

const basePath = process.env.NEXT_PUBLIC_SITE_API;
export const defaultApiConfiguration = new BilhandelConfiguration({
  basePath,
});

export const useApiConfiguration = () => {
  const { middleware, loginToken } = useMiddleware();
  const apiConfiguration = useMemo(() => {
    if (loginToken != null) {
      return new BilhandelConfiguration({
        basePath: basePath,
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
        middleware: [middleware],
      });
    }
    return defaultApiConfiguration;
  }, [loginToken, middleware]);
  return { apiConfiguration };
};

export const useApi = () => {
  const { apiConfiguration } = useApiConfiguration();
  const api = useMemo(() => {
    return {
      searchApi: new SearchApi(apiConfiguration),
      searchAgentApi: new SearchAgentApi(apiConfiguration),
      dealerApi: new DealerApi(apiConfiguration),
      authApi: new AuthApi(apiConfiguration),
      likeApi: new LikeApi(apiConfiguration),
      classifiedApi: new ClassifiedApi(apiConfiguration),
      geoLocationApi: new GeoLocationApi(apiConfiguration),
      classifiedCreationApi: new ClassifiedCreationApi(apiConfiguration),
      serviceApi: new ServiceApi(apiConfiguration),
      carpalApi: new CarpalApi(apiConfiguration),
      chatbotApi: new ChatbotApi(apiConfiguration),
      searchbotApi: new SearchbotApi(apiConfiguration),
    };
  }, [apiConfiguration]);
  return api;
};