/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SourceData,
    SourceDataFromJSON,
    SourceDataFromJSONTyped,
    SourceDataToJSON,
} from './SourceData';

/**
 * 
 * @export
 * @interface SourceDataSpec
 */
export interface SourceDataSpec {
    /**
     * 
     * @type {string}
     * @memberof SourceDataSpec
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SourceDataSpec
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SourceDataSpec
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceDataSpec
     */
    spec?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceDataSpec
     */
    sourceDataId?: string;
    /**
     * 
     * @type {SourceData}
     * @memberof SourceDataSpec
     */
    sourceData?: SourceData;
}

export function SourceDataSpecFromJSON(json: any): SourceDataSpec {
    return SourceDataSpecFromJSONTyped(json, false);
}

export function SourceDataSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceDataSpec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'spec': !exists(json, 'spec') ? undefined : json['spec'],
        'sourceDataId': !exists(json, 'sourceDataId') ? undefined : json['sourceDataId'],
        'sourceData': !exists(json, 'sourceData') ? undefined : SourceDataFromJSON(json['sourceData']),
    };
}

export function SourceDataSpecToJSON(value?: SourceDataSpec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'title': value.title,
        'spec': value.spec,
        'sourceDataId': value.sourceDataId,
        'sourceData': SourceDataToJSON(value.sourceData),
    };
}

