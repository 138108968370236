/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessedDataType,
    ProcessedDataTypeFromJSON,
    ProcessedDataTypeFromJSONTyped,
    ProcessedDataTypeToJSON,
} from './ProcessedDataType';
import {
    PromptConfig,
    PromptConfigFromJSON,
    PromptConfigFromJSONTyped,
    PromptConfigToJSON,
} from './PromptConfig';
import {
    SourceData,
    SourceDataFromJSON,
    SourceDataFromJSONTyped,
    SourceDataToJSON,
} from './SourceData';

/**
 * 
 * @export
 * @interface ProcessedData
 */
export interface ProcessedData {
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    promptConfigId?: string;
    /**
     * 
     * @type {PromptConfig}
     * @memberof ProcessedData
     */
    promptConfig?: PromptConfig;
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    previousProcessedDataId?: string | null;
    /**
     * 
     * @type {ProcessedData}
     * @memberof ProcessedData
     */
    previousProcessedData?: ProcessedData;
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    sourceDataId?: string | null;
    /**
     * 
     * @type {SourceData}
     * @memberof ProcessedData
     */
    sourceData?: SourceData;
    /**
     * 
     * @type {Date}
     * @memberof ProcessedData
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    result?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessedData
     */
    typeId?: string;
    /**
     * 
     * @type {ProcessedDataType}
     * @memberof ProcessedData
     */
    type?: ProcessedDataType;
    /**
     * 
     * @type {Array<ProcessedData>}
     * @memberof ProcessedData
     */
    processedDatas?: Array<ProcessedData> | null;
}

export function ProcessedDataFromJSON(json: any): ProcessedData {
    return ProcessedDataFromJSONTyped(json, false);
}

export function ProcessedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'promptConfigId': !exists(json, 'promptConfigId') ? undefined : json['promptConfigId'],
        'promptConfig': !exists(json, 'promptConfig') ? undefined : PromptConfigFromJSON(json['promptConfig']),
        'previousProcessedDataId': !exists(json, 'previousProcessedDataId') ? undefined : json['previousProcessedDataId'],
        'previousProcessedData': !exists(json, 'previousProcessedData') ? undefined : ProcessedDataFromJSON(json['previousProcessedData']),
        'sourceDataId': !exists(json, 'sourceDataId') ? undefined : json['sourceDataId'],
        'sourceData': !exists(json, 'sourceData') ? undefined : SourceDataFromJSON(json['sourceData']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'result': !exists(json, 'result') ? undefined : json['result'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'type': !exists(json, 'type') ? undefined : ProcessedDataTypeFromJSON(json['type']),
        'processedDatas': !exists(json, 'processedDatas') ? undefined : (json['processedDatas'] === null ? null : (json['processedDatas'] as Array<any>).map(ProcessedDataFromJSON)),
    };
}

export function ProcessedDataToJSON(value?: ProcessedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'promptConfigId': value.promptConfigId,
        'promptConfig': PromptConfigToJSON(value.promptConfig),
        'previousProcessedDataId': value.previousProcessedDataId,
        'previousProcessedData': ProcessedDataToJSON(value.previousProcessedData),
        'sourceDataId': value.sourceDataId,
        'sourceData': SourceDataToJSON(value.sourceData),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'result': value.result,
        'typeId': value.typeId,
        'type': ProcessedDataTypeToJSON(value.type),
        'processedDatas': value.processedDatas === undefined ? undefined : (value.processedDatas === null ? null : (value.processedDatas as Array<any>).map(ProcessedDataToJSON)),
    };
}

