import {
  CmsSrpBottomBlockDto,
  SearchPageAttribute,
  SearchPageFields,
  SearchPageMakeModelVariantOption,
  SearchPageOptions,
  SearchResult,
  SeoUrl,
  SeoBlock,
  MileagePriceData,
  SearchDisplayResult,
} from '@/shared/lib-api';
import { GetSearchPageField } from '@/shared/util/helpers/general-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { AuthState } from './AuthSlice';

export interface SRPState {
  searchData?: SearchResult;
  noResultsSearchData?: SearchResult;
  searchFields?: SearchPageFields;
  modelAttributes?: SearchPageAttribute[];
  searchOptions?: SearchPageOptions;
  submitCount?: number;
  seoOverride?: SeoUrl;
  cmsInfo?: CmsSrpBottomBlockDto;
  srpHeadline?: string;
  srpText?: string;
}

export const initialSearchAttribute: SearchPageAttribute = {
  label: '',
  value: '',
};

export const initialSortAttribute: SearchPageAttribute = {
  label: 'Bedste match',
  value: 'bedste-match',
};

export const sortValues: SearchPageAttribute[] = [
  initialSortAttribute,
  { value: 'billigst', label: 'Pris: Billigste først' },
  { value: 'dyrest', label: 'Pris: Dyreste først' },
  { value: 'aeldst', label: 'Dato: Ældste først' },
  { value: 'nyest', label: 'Dato: Nyeste først' },
  { value: 'aargang', label: 'Årgang: Ældste først' },
  { value: 'aargangAeldst', label: 'Årgang: Yngste først' },
  { value: 'laengst', label: 'Kilometer: Højeste først' },
  { value: 'kortest', label: 'Kilometer: Laveste først' },
  { value: 'stoersteBatteri', label: 'Batterikapacitet: Største først' },
  { value: 'mindsteBatteri', label: 'Batterikapacitet: Mindste først' },
  { value: 'stoersteRaekkevidde', label: 'Rækkevidde: Største først' },
  { value: 'mindsteRaekkevidde', label: 'Rækkevidde: Mindste først' },
  { value: 'laengstpaaliteren', label: 'Længst på literen' },
  { value: 'kortestpaaliteren', label: 'Kortest på literen' },
];

export const initialMakeModelVariantOption: SearchPageMakeModelVariantOption = {
  variant: '',
  brand: initialSearchAttribute,
  model: initialSearchAttribute,
};

export const initialSearchPageOptions: SearchPageOptions = {
  makeModelVariantOptions: [initialMakeModelVariantOption],
  category: initialSearchAttribute,
  carTypes: [],
  fuelTypes: [],
  colors: [],
  equipment: [],
  leasingType: [],
  limitSearch: [],
  gearBoxTypes: [],
  yearFrom: initialSearchAttribute,
  profileType: initialSearchAttribute,
  pricePerMonthFrom: initialSearchAttribute,
  pricePerMonthTo: initialSearchAttribute,
  priceFrom: initialSearchAttribute,
  priceTo: initialSearchAttribute,
  zipCode: '',
  searchQuery: '',
  pageNumber: 1,
  pageSize: 12,
  sort: initialSortAttribute,
};

export const initialState: SRPState = {
  searchData: undefined,
  searchFields: undefined,
  searchOptions: initialSearchPageOptions,
  submitCount: 0,
  seoOverride: undefined,
  cmsInfo: undefined,
  srpHeadline: '',
  srpText: '',
};

export const SRPSlice = createSlice({
  name: 'SRPSearchSlice',
  initialState,
  reducers: {
    SetSrpSearchData: (state, action: PayloadAction<SearchResult>) => {
      state.searchData = action.payload;
    },
    SetSrpNoResultsSearchData: (state, action: PayloadAction<SearchResult>) => {
      state.noResultsSearchData = action.payload;
    },
    SetSrpSearchFields: (state, action: PayloadAction<SearchPageFields>) => {
      state.searchFields = action.payload;
    },
    SetModelAttributes: (
      state,
      action: PayloadAction<SearchPageAttribute[]>,
    ) => {
      state.modelAttributes = action.payload;
    },
    SetSrpCmsInfo: (state, action: PayloadAction<CmsSrpBottomBlockDto>) => {
      state.cmsInfo = action.payload;
    },
    SetSeoOverrides: (state, action: PayloadAction<SeoUrl>) => {
      state.seoOverride = action.payload;
    },
    SetSRPHeadline: (state, action: PayloadAction<string>) => {
      state.srpHeadline = action.payload;
    },
    SetSRPText: (state, action: PayloadAction<string>) => {
      state.srpText = action.payload;
    },
    Submit: state => {
      state.submitCount++;
    },
    Reset: state => {
      let modelField = GetSearchPageField(
        state.searchFields?.defaultSection,
        'model',
      );
      modelField.attributes = [];
      state.searchOptions = initialSearchPageOptions;
      state.submitCount++;
    },
    SetSRPModels: (state, action: PayloadAction<SearchPageAttribute[]>) => {
      let modelField = GetSearchPageField(
        state.searchFields?.defaultSection,
        'model',
      );
      modelField.attributes = action.payload;
    },
    SetSrpSearchOptions: (state, action: PayloadAction<SearchPageOptions>) => {
      state.searchOptions = action.payload;
    },
    SetSrpSearchOption: (
      state,
      action: PayloadAction<{
        [key: string]:
          | string
          | string[]
          | SearchPageMakeModelVariantOption[]
          | number
          | SearchPageAttribute
          | SearchPageAttribute[];
      }>,
    ) => {
      if (action.payload === undefined) {
        return;
      }
      let key = Object.keys(action.payload)[0];
      if (key !== 'pageNumber') {
        state.searchOptions.pageNumber = 1;
      }
      let value = Object.values(action.payload)[0];
      var formNameComponents = key.split('.');
      if (formNameComponents.length === 3) {
        //makemodelvariant change
        if (formNameComponents[2] === 'brand') {
          ///reset model value
          state.searchOptions[formNameComponents[0]][formNameComponents[1]][
            'model'
          ] = initialSearchAttribute;
          if (value === initialSearchAttribute) {
            ///reset model attributes
            let modelField = GetSearchPageField(
              state.searchFields?.defaultSection,
              'model',
            );
            modelField.attributes = [];
          }
        }
        state.searchOptions[formNameComponents[0]][formNameComponents[1]][
          formNameComponents[2]
        ] = value;
      } else if (key === 'makeModelVariantOptions') {
        //makeModelVariantOptions change (more makemodelvaraint options are added or removed)
        let lastMakeModelVariant = (
          value as SearchPageMakeModelVariantOption[]
        )[(value as SearchPageMakeModelVariantOption[]).length - 1];
        if (lastMakeModelVariant.brand.value === '') {
          ///reset model attributes
          let modelField = GetSearchPageField(
            state.searchFields?.defaultSection,
            'model',
          );
          modelField.attributes = [];
        }
        state.searchOptions[key] = value as SearchPageMakeModelVariantOption[];
      } else {
        //default change
        state.searchOptions[key] = value;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(
      HYDRATE,
      (
        state,
        action:
          | PayloadAction<{
              auth: AuthState & PersistPartial;
              srpReducer: SRPState;
            }>
          | any,
      ) => {
        return {
          ...state,
          ...action.payload.srpReducer,
        };
      },
    );
  },
});

export const {
  SetSrpSearchData,
  SetModelAttributes,
  SetSrpSearchFields,
  SetSrpCmsInfo,
  SetSRPModels,
  SetSrpSearchOption,
  SetSrpSearchOptions,
  SetSeoOverrides,
  SetSRPHeadline,
  SetSRPText,
  Submit,
  Reset,
  SetSrpNoResultsSearchData,
} = SRPSlice.actions;

export default SRPSlice.reducer;
