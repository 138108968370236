/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserItemVehicleNotificationPermissions,
    UserItemVehicleNotificationPermissionsFromJSON,
    UserItemVehicleNotificationPermissionsFromJSONTyped,
    UserItemVehicleNotificationPermissionsToJSON,
} from './UserItemVehicleNotificationPermissions';

/**
 * 
 * @export
 * @interface UserItemPermissions
 */
export interface UserItemPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    priceCheck?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    newsLetter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    getOffers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    terms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    notifyOnChangesToLikes?: boolean;
    /**
     * 
     * @type {UserItemVehicleNotificationPermissions}
     * @memberof UserItemPermissions
     */
    userItemVehicleNotificationPermissions?: UserItemVehicleNotificationPermissions;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemPermissions
     */
    consentForDealerContactOnFavorites?: boolean | null;
}

export function UserItemPermissionsFromJSON(json: any): UserItemPermissions {
    return UserItemPermissionsFromJSONTyped(json, false);
}

export function UserItemPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItemPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceCheck': !exists(json, 'priceCheck') ? undefined : json['priceCheck'],
        'newsLetter': !exists(json, 'newsLetter') ? undefined : json['newsLetter'],
        'getOffers': !exists(json, 'getOffers') ? undefined : json['getOffers'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'notifyOnChangesToLikes': !exists(json, 'notifyOnChangesToLikes') ? undefined : json['notifyOnChangesToLikes'],
        'userItemVehicleNotificationPermissions': !exists(json, 'userItemVehicleNotificationPermissions') ? undefined : UserItemVehicleNotificationPermissionsFromJSON(json['userItemVehicleNotificationPermissions']),
        'consentForDealerContactOnFavorites': !exists(json, 'consentForDealerContactOnFavorites') ? undefined : json['consentForDealerContactOnFavorites'],
    };
}

export function UserItemPermissionsToJSON(value?: UserItemPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceCheck': value.priceCheck,
        'newsLetter': value.newsLetter,
        'getOffers': value.getOffers,
        'terms': value.terms,
        'notifyOnChangesToLikes': value.notifyOnChangesToLikes,
        'userItemVehicleNotificationPermissions': UserItemVehicleNotificationPermissionsToJSON(value.userItemVehicleNotificationPermissions),
        'consentForDealerContactOnFavorites': value.consentForDealerContactOnFavorites,
    };
}

