import { useTranslation } from 'react-i18next';
import { bool, number, string } from 'yup';

export const useValidationSchemes = () => {
  const { t } = useTranslation();
  return {
    emailRequired: string()
      .email(t('ErrorLabel.EmailValid'))
      .required(t('ErrorLabel.EmailRequired')),
    passwordRequired: string().required(t('ErrorLabel.PasswordRequired')),
    nameRequired: string().required(t('ErrorLabel.NameRequired')),
    termsAccepted: bool().oneOf([true], t('ErrorLabel.TermsRequired')),
    newsAccepted: bool(),
    zipCodeRequired: number()
      .required(t('ErrorLabel.ZipCodeRequired'))
      .min(1000, t('ErrorLabel.Min', { field: 'Postnummer', min: '1000' }))
      .max(9990, t('ErrorLabel.Max', { field: 'Postnummer', max: '9990' })),
    licensePlate: string().length(7, t('ErrorLabel.LicensePlateLength')),
    phoneRequired: string(),
    streetNameRequired: string().required(t('ErrorLabel.StreetNameRequired')),
    streetNumberRequired: string().required(
      t('ErrorLabel.StreetNumberRequired'),
    ),
    tokenRequired: string().required(t('ErrorLabel.TokenRequired')),
  };
};
