/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientAction
 */
export interface ClientAction {
    /**
     * 
     * @type {string}
     * @memberof ClientAction
     */
    actionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAction
     */
    runId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAction
     */
    params?: string | null;
}

export function ClientActionFromJSON(json: any): ClientAction {
    return ClientActionFromJSONTyped(json, false);
}

export function ClientActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
        'params': !exists(json, 'params') ? undefined : json['params'],
    };
}

export function ClientActionToJSON(value?: ClientAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'runId': value.runId,
        'params': value.params,
    };
}

