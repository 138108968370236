import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ProfileInformationForm, ProfileInformationFormValue } from './profile-information-form';
import { ProfileInformationData } from '@/shared/lib-api';
import { FormikModalButton } from '../../default-components/button/formik-modal-button';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useAppDispatch } from '@/shared/store/hooks';
import { useApi } from '@/shared/util/api';
import { LoadingState } from '@/shared/util/general-helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setUser } from '@/shared/store/reducers/AuthSlice';
import { DefaultReponseError } from '../../default-components/input/default-response-error';

type UpdateProfileInformationModalProps = {
  open: boolean;
  onClose: () => void;
};

const UpdateProfileInformationModal: React.FC<UpdateProfileInformationModalProps> = ({
  open,
  onClose
}) => {

  const { handleRequest, errorMessage, requestState } = useRequestHelper();
  const { authApi } = useApi();
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(
    async (values: ProfileInformationFormValue) => {

      const profileInformationData: ProfileInformationData = {...values};

      let request = authApi.apiAuthUpdateprofileinformationdataPost({
        profileInformationData: profileInformationData,
      });

      const [response, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(setUser(response));
        onClose();
      }
    },
    [authApi, dispatch, handleRequest],
  );

  const handleClose = async () => {
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} id='updateProfileInformationModal'>
        <DialogTitle  display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box paddingLeft={'5px'} >
           Tjek om dine oplysninger er korrekte
          </Box>
        <IconButton onClick={handleClose}>
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color: '#000'}}>
          <ProfileInformationForm onSubmit={onSubmit}>
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
              marginTop={2}
              >
                <FormikModalButton
                  loadingText={'Labels.Saving'}
                  text={'Labels.Save'}
                />
            </Grid>
          </ProfileInformationForm>
          <DefaultReponseError state={requestState} messageError={errorMessage} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateProfileInformationModal;
