/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchResult,
    SearchResultFromJSON,
    SearchResultFromJSONTyped,
    SearchResultToJSON,
} from './SearchResult';

/**
 * 
 * @export
 * @interface AiGuideCategoryResponse
 */
export interface AiGuideCategoryResponse {
    /**
     * 
     * @type {SearchResult}
     * @memberof AiGuideCategoryResponse
     */
    searchResult?: SearchResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiGuideCategoryResponse
     */
    relatedModels?: Array<string> | null;
}

export function AiGuideCategoryResponseFromJSON(json: any): AiGuideCategoryResponse {
    return AiGuideCategoryResponseFromJSONTyped(json, false);
}

export function AiGuideCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AiGuideCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchResult': !exists(json, 'searchResult') ? undefined : SearchResultFromJSON(json['searchResult']),
        'relatedModels': !exists(json, 'relatedModels') ? undefined : json['relatedModels'],
    };
}

export function AiGuideCategoryResponseToJSON(value?: AiGuideCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchResult': SearchResultToJSON(value.searchResult),
        'relatedModels': value.relatedModels,
    };
}

