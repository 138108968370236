/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AiGuideCategoryResponse,
    AiGuideCategoryResponseFromJSON,
    AiGuideCategoryResponseToJSON,
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemToJSON,
    ClassifiedItemAttachment,
    ClassifiedItemAttachmentFromJSON,
    ClassifiedItemAttachmentToJSON,
    FrontPageRecommendationsRequest,
    FrontPageRecommendationsRequestFromJSON,
    FrontPageRecommendationsRequestToJSON,
    InitResponse,
    InitResponseFromJSON,
    InitResponseToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SearchFrontpageInitResponse,
    SearchFrontpageInitResponseFromJSON,
    SearchFrontpageInitResponseToJSON,
    SearchInitRequest,
    SearchInitRequestFromJSON,
    SearchInitRequestToJSON,
    SearchInitResponse,
    SearchInitResponseFromJSON,
    SearchInitResponseToJSON,
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeToJSON,
    SearchPageFields,
    SearchPageFieldsFromJSON,
    SearchPageFieldsToJSON,
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsToJSON,
    SearchResult,
    SearchResultFromJSON,
    SearchResultToJSON,
    SourceDataSpec,
    SourceDataSpecFromJSON,
    SourceDataSpecToJSON,
} from '../models';

export interface ApiSearchGetseosrpGetRequest {
    category?: string;
    url?: string;
}

export interface ApiSearchInitaicategoryPostRequest {
    searchagentId?: number;
}

export interface ApiSearchInitcustomfromserverPostRequest {
    searchagentId?: number;
    searchInitRequest?: SearchInitRequest;
}

export interface ApiSearchInitfromserverPostRequest {
    searchInitRequest?: SearchInitRequest;
}

export interface ApiSearchInteriorImagesGetRequest {
    brand?: string;
    model?: string;
}

export interface ApiSearchRecommendationsPostRequest {
    frontPageRecommendationsRequest?: FrontPageRecommendationsRequest;
}

export interface ApiSearchSearchPostRequest {
    excludeAggregations?: boolean;
    minImageCount?: number;
    searchPageOptions?: SearchPageOptions;
}

export interface ApiSearchSearchmodelattributesGetRequest {
    brand?: string;
    isSyi?: boolean;
}

export interface ApiSearchSearchsimilarPostRequest {
    searchPageOptions?: SearchPageOptions;
}

export interface ApiSearchSpecsGetRequest {
    cmsContentId?: string;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchAdminsearchesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/adminsearches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSearchAdminsearchesGet(initOverrides?: RequestInit): Promise<void> {
        await this.apiSearchAdminsearchesGetRaw(initOverrides);
    }

    /**
     */
    async apiSearchGetseosrpGetRaw(requestParameters: ApiSearchGetseosrpGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitResponse>> {
        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/getseosrp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchGetseosrpGet(requestParameters: ApiSearchGetseosrpGetRequest = {}, initOverrides?: RequestInit): Promise<InitResponse> {
        const response = await this.apiSearchGetseosrpGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchInitaicategoryPostRaw(requestParameters: ApiSearchInitaicategoryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AiGuideCategoryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchagentId !== undefined) {
            queryParameters['searchagentId'] = requestParameters.searchagentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/initaicategory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AiGuideCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchInitaicategoryPost(requestParameters: ApiSearchInitaicategoryPostRequest = {}, initOverrides?: RequestInit): Promise<AiGuideCategoryResponse> {
        const response = await this.apiSearchInitaicategoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchInitcustomfromserverPostRaw(requestParameters: ApiSearchInitcustomfromserverPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchInitResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchagentId !== undefined) {
            queryParameters['searchagentId'] = requestParameters.searchagentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/search/initcustomfromserver`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchInitRequestToJSON(requestParameters.searchInitRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchInitResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchInitcustomfromserverPost(requestParameters: ApiSearchInitcustomfromserverPostRequest = {}, initOverrides?: RequestInit): Promise<SearchInitResponse> {
        const response = await this.apiSearchInitcustomfromserverPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchInitfromserverPostRaw(requestParameters: ApiSearchInitfromserverPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchInitResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/search/initfromserver`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchInitRequestToJSON(requestParameters.searchInitRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchInitResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchInitfromserverPost(requestParameters: ApiSearchInitfromserverPostRequest = {}, initOverrides?: RequestInit): Promise<SearchInitResponse> {
        const response = await this.apiSearchInitfromserverPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchInitfrontfromserverGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchFrontpageInitResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/initfrontfromserver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchFrontpageInitResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchInitfrontfromserverGet(initOverrides?: RequestInit): Promise<SearchFrontpageInitResponse> {
        const response = await this.apiSearchInitfrontfromserverGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchInteriorImagesGetRaw(requestParameters: ApiSearchInteriorImagesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItemAttachment>>> {
        const queryParameters: any = {};

        if (requestParameters.brand !== undefined) {
            queryParameters['brand'] = requestParameters.brand;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/interior-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemAttachmentFromJSON));
    }

    /**
     */
    async apiSearchInteriorImagesGet(requestParameters: ApiSearchInteriorImagesGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassifiedItemAttachment>> {
        const response = await this.apiSearchInteriorImagesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchRecommendationsPostRaw(requestParameters: ApiSearchRecommendationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/search/recommendations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontPageRecommendationsRequestToJSON(requestParameters.frontPageRecommendationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemFromJSON));
    }

    /**
     */
    async apiSearchRecommendationsPost(requestParameters: ApiSearchRecommendationsPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassifiedItem>> {
        const response = await this.apiSearchRecommendationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchPostRaw(requestParameters: ApiSearchSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchResult>> {
        const queryParameters: any = {};

        if (requestParameters.excludeAggregations !== undefined) {
            queryParameters['excludeAggregations'] = requestParameters.excludeAggregations;
        }

        if (requestParameters.minImageCount !== undefined) {
            queryParameters['minImageCount'] = requestParameters.minImageCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/search/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPageOptionsToJSON(requestParameters.searchPageOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchSearchPost(requestParameters: ApiSearchSearchPostRequest = {}, initOverrides?: RequestInit): Promise<SearchResult> {
        const response = await this.apiSearchSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchbrandattributesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/searchbrandattributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiSearchSearchbrandattributesGet(initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiSearchSearchbrandattributesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchfueltypeattributesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/searchfueltypeattributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiSearchSearchfueltypeattributesGet(initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiSearchSearchfueltypeattributesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchmodelattributesGetRaw(requestParameters: ApiSearchSearchmodelattributesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        if (requestParameters.brand !== undefined) {
            queryParameters['brand'] = requestParameters.brand;
        }

        if (requestParameters.isSyi !== undefined) {
            queryParameters['isSyi'] = requestParameters.isSyi;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/searchmodelattributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiSearchSearchmodelattributesGet(requestParameters: ApiSearchSearchmodelattributesGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiSearchSearchmodelattributesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchsimilarPostRaw(requestParameters: ApiSearchSearchsimilarPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/search/searchsimilar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPageOptionsToJSON(requestParameters.searchPageOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchSearchsimilarPost(requestParameters: ApiSearchSearchsimilarPostRequest = {}, initOverrides?: RequestInit): Promise<SearchResult> {
        const response = await this.apiSearchSearchsimilarPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSearchsrpfieldsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchPageFields>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/searchsrpfields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchPageFieldsFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchSearchsrpfieldsGet(initOverrides?: RequestInit): Promise<SearchPageFields> {
        const response = await this.apiSearchSearchsrpfieldsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchSpecsGetRaw(requestParameters: ApiSearchSpecsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SourceDataSpec>>> {
        const queryParameters: any = {};

        if (requestParameters.cmsContentId !== undefined) {
            queryParameters['cmsContentId'] = requestParameters.cmsContentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/search/specs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SourceDataSpecFromJSON));
    }

    /**
     */
    async apiSearchSpecsGet(requestParameters: ApiSearchSpecsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SourceDataSpec>> {
        const response = await this.apiSearchSpecsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
