/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dealer,
    DealerFromJSON,
    DealerFromJSONTyped,
    DealerToJSON,
} from './Dealer';
import {
    MileagePriceData,
    MileagePriceDataFromJSON,
    MileagePriceDataFromJSONTyped,
    MileagePriceDataToJSON,
} from './MileagePriceData';
import {
    SearchDisplayResult,
    SearchDisplayResultFromJSON,
    SearchDisplayResultFromJSONTyped,
    SearchDisplayResultToJSON,
} from './SearchDisplayResult';
import {
    SearchResult,
    SearchResultFromJSON,
    SearchResultFromJSONTyped,
    SearchResultToJSON,
} from './SearchResult';
import {
    SeoBlock,
    SeoBlockFromJSON,
    SeoBlockFromJSONTyped,
    SeoBlockToJSON,
} from './SeoBlock';
import {
    SeoUrl,
    SeoUrlFromJSON,
    SeoUrlFromJSONTyped,
    SeoUrlToJSON,
} from './SeoUrl';

/**
 * 
 * @export
 * @interface SeoPageOptions
 */
export interface SeoPageOptions {
    /**
     * 
     * @type {SearchResult}
     * @memberof SeoPageOptions
     */
    allResults?: SearchResult;
    /**
     * 
     * @type {SearchDisplayResult}
     * @memberof SeoPageOptions
     */
    topModelResults?: SearchDisplayResult;
    /**
     * 
     * @type {SearchDisplayResult}
     * @memberof SeoPageOptions
     */
    topVariantResults?: SearchDisplayResult;
    /**
     * 
     * @type {SearchDisplayResult}
     * @memberof SeoPageOptions
     */
    topBrandResults?: SearchDisplayResult;
    /**
     * 
     * @type {Array<MileagePriceData>}
     * @memberof SeoPageOptions
     */
    mileagePriceData?: Array<MileagePriceData> | null;
    /**
     * 
     * @type {number}
     * @memberof SeoPageOptions
     */
    totalDealers?: number | null;
    /**
     * 
     * @type {Array<SeoBlock>}
     * @memberof SeoPageOptions
     */
    seoBlocks?: Array<SeoBlock> | null;
    /**
     * 
     * @type {SeoUrl}
     * @memberof SeoPageOptions
     */
    seoData?: SeoUrl;
    /**
     * 
     * @type {SearchDisplayResult}
     * @memberof SeoPageOptions
     */
    alternativeToModelResults?: SearchDisplayResult;
    /**
     * 
     * @type {Array<Dealer>}
     * @memberof SeoPageOptions
     */
    selectedDealers?: Array<Dealer> | null;
    /**
     * 
     * @type {number}
     * @memberof SeoPageOptions
     */
    makeModelDealerCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SeoPageOptions
     */
    variantsPriceText?: string | null;
}

export function SeoPageOptionsFromJSON(json: any): SeoPageOptions {
    return SeoPageOptionsFromJSONTyped(json, false);
}

export function SeoPageOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoPageOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allResults': !exists(json, 'allResults') ? undefined : SearchResultFromJSON(json['allResults']),
        'topModelResults': !exists(json, 'topModelResults') ? undefined : SearchDisplayResultFromJSON(json['topModelResults']),
        'topVariantResults': !exists(json, 'topVariantResults') ? undefined : SearchDisplayResultFromJSON(json['topVariantResults']),
        'topBrandResults': !exists(json, 'topBrandResults') ? undefined : SearchDisplayResultFromJSON(json['topBrandResults']),
        'mileagePriceData': !exists(json, 'mileagePriceData') ? undefined : (json['mileagePriceData'] === null ? null : (json['mileagePriceData'] as Array<any>).map(MileagePriceDataFromJSON)),
        'totalDealers': !exists(json, 'totalDealers') ? undefined : json['totalDealers'],
        'seoBlocks': !exists(json, 'seoBlocks') ? undefined : (json['seoBlocks'] === null ? null : (json['seoBlocks'] as Array<any>).map(SeoBlockFromJSON)),
        'seoData': !exists(json, 'seoData') ? undefined : SeoUrlFromJSON(json['seoData']),
        'alternativeToModelResults': !exists(json, 'alternativeToModelResults') ? undefined : SearchDisplayResultFromJSON(json['alternativeToModelResults']),
        'selectedDealers': !exists(json, 'selectedDealers') ? undefined : (json['selectedDealers'] === null ? null : (json['selectedDealers'] as Array<any>).map(DealerFromJSON)),
        'makeModelDealerCount': !exists(json, 'makeModelDealerCount') ? undefined : json['makeModelDealerCount'],
        'variantsPriceText': !exists(json, 'variantsPriceText') ? undefined : json['variantsPriceText'],
    };
}

export function SeoPageOptionsToJSON(value?: SeoPageOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allResults': SearchResultToJSON(value.allResults),
        'topModelResults': SearchDisplayResultToJSON(value.topModelResults),
        'topVariantResults': SearchDisplayResultToJSON(value.topVariantResults),
        'topBrandResults': SearchDisplayResultToJSON(value.topBrandResults),
        'mileagePriceData': value.mileagePriceData === undefined ? undefined : (value.mileagePriceData === null ? null : (value.mileagePriceData as Array<any>).map(MileagePriceDataToJSON)),
        'totalDealers': value.totalDealers,
        'seoBlocks': value.seoBlocks === undefined ? undefined : (value.seoBlocks === null ? null : (value.seoBlocks as Array<any>).map(SeoBlockToJSON)),
        'seoData': SeoUrlToJSON(value.seoData),
        'alternativeToModelResults': SearchDisplayResultToJSON(value.alternativeToModelResults),
        'selectedDealers': value.selectedDealers === undefined ? undefined : (value.selectedDealers === null ? null : (value.selectedDealers as Array<any>).map(DealerToJSON)),
        'makeModelDealerCount': value.makeModelDealerCount,
        'variantsPriceText': value.variantsPriceText,
    };
}

