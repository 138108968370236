/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientAction,
    ClientActionFromJSON,
    ClientActionFromJSONTyped,
    ClientActionToJSON,
} from './ClientAction';

/**
 * 
 * @export
 * @interface SearchbotMessageRequest
 */
export interface SearchbotMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchbotMessageRequest
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchbotMessageRequest
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchbotMessageRequest
     */
    threadId?: string | null;
    /**
     * 
     * @type {ClientAction}
     * @memberof SearchbotMessageRequest
     */
    clientAction?: ClientAction;
}

export function SearchbotMessageRequestFromJSON(json: any): SearchbotMessageRequest {
    return SearchbotMessageRequestFromJSONTyped(json, false);
}

export function SearchbotMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchbotMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
        'clientAction': !exists(json, 'clientAction') ? undefined : ClientActionFromJSON(json['clientAction']),
    };
}

export function SearchbotMessageRequestToJSON(value?: SearchbotMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'url': value.url,
        'threadId': value.threadId,
        'clientAction': ClientActionToJSON(value.clientAction),
    };
}

