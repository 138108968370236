import { Colors } from '@/shared/util/colors';
import { theme } from '@/web/util/theme';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import exclamation from '@/shared/icons/circle-exclamation.svg';
import { LoadingState } from '@/shared/util/general-helpers';
import Image from '@/web/views/components/image/image';

export const DefaultReponseError: React.FC<{
  state: LoadingState;
  messageNotFound?: string;
  messageError?: string;
  messageNotFoundShort?: string;
  messageErrorShort?: string;
}> = props => {
  const containerStyle = useMemo(
    () => ({
      backgroundColor: Colors.Background.VipWriteToSeller,
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '16px',
      marginBottom: '16px',
      padding: '16px',
      zIndex: 50,
      borderRadius: '3px',
      border: '2px solid ' + Colors.Error,
    }),
    [],
  );
  return (
    <>
      {(props.state === LoadingState.NotFound ||
        props.state === LoadingState.Error) && (
        <Box sx={containerStyle} fontSize={12} color={Colors.Error}>
          <Box display="flex" alignItems="center">
            <Image height={14} width={14} alt="Fejl" src={exclamation.src} />
            <span style={{ verticalAlign: 'middle', marginLeft: '6.42px' }}>
              {props.state === LoadingState.NotFound
                ? props.messageNotFound
                : props.messageError}
            </span>
          </Box>
        </Box>
      )}
    </>
  );
};
