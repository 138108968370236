/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchbotMessageRequest,
    SearchbotMessageRequestFromJSON,
    SearchbotMessageRequestToJSON,
    SearchbotMessageResponse,
    SearchbotMessageResponseFromJSON,
    SearchbotMessageResponseToJSON,
} from '../models';

export interface ApiSearchbotSendMessagePostRequest {
    searchbotMessageRequest?: SearchbotMessageRequest;
}

/**
 * 
 */
export class SearchbotApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchbotSendMessagePostRaw(requestParameters: ApiSearchbotSendMessagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchbotMessageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/searchbot/send-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchbotMessageRequestToJSON(requestParameters.searchbotMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchbotMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchbotSendMessagePost(requestParameters: ApiSearchbotSendMessagePostRequest = {}, initOverrides?: RequestInit): Promise<SearchbotMessageResponse> {
        const response = await this.apiSearchbotSendMessagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
