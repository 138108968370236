/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessedData,
    ProcessedDataFromJSON,
    ProcessedDataFromJSONTyped,
    ProcessedDataToJSON,
} from './ProcessedData';
import {
    SourceDataSpec,
    SourceDataSpecFromJSON,
    SourceDataSpecFromJSONTyped,
    SourceDataSpecToJSON,
} from './SourceDataSpec';
import {
    SourceType,
    SourceTypeFromJSON,
    SourceTypeFromJSONTyped,
    SourceTypeToJSON,
} from './SourceType';

/**
 * 
 * @export
 * @interface SourceData
 */
export interface SourceData {
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    input?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SourceData
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    editedTitle?: string | null;
    /**
     * 
     * @type {SourceType}
     * @memberof SourceData
     */
    type?: SourceType;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    website?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SourceData
     */
    generated?: boolean;
    /**
     * 
     * @type {Array<ProcessedData>}
     * @memberof SourceData
     */
    processedDatas?: Array<ProcessedData> | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    cmsContentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    agilityWriterText?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SourceData
     */
    agilityWriterAddedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SourceData
     */
    dontOverrideAgilityWriterText?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SourceData
     */
    fromDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SourceData
     */
    toDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SourceData
     */
    publishedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SourceData
     */
    previouslyPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SourceData
     */
    scheduledForPublished?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SourceData
     */
    germanWordsInText?: string | null;
    /**
     * 
     * @type {Array<SourceDataSpec>}
     * @memberof SourceData
     */
    sourceDataSpecs?: Array<SourceDataSpec> | null;
}

export function SourceDataFromJSON(json: any): SourceData {
    return SourceDataFromJSONTyped(json, false);
}

export function SourceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'input': !exists(json, 'input') ? undefined : json['input'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'editedTitle': !exists(json, 'editedTitle') ? undefined : json['editedTitle'],
        'type': !exists(json, 'type') ? undefined : SourceTypeFromJSON(json['type']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'generated': !exists(json, 'generated') ? undefined : json['generated'],
        'processedDatas': !exists(json, 'processedDatas') ? undefined : (json['processedDatas'] === null ? null : (json['processedDatas'] as Array<any>).map(ProcessedDataFromJSON)),
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'cmsContentId': !exists(json, 'cmsContentId') ? undefined : json['cmsContentId'],
        'agilityWriterText': !exists(json, 'agilityWriterText') ? undefined : json['agilityWriterText'],
        'agilityWriterAddedDate': !exists(json, 'agilityWriterAddedDate') ? undefined : (json['agilityWriterAddedDate'] === null ? null : new Date(json['agilityWriterAddedDate'])),
        'dontOverrideAgilityWriterText': !exists(json, 'dontOverrideAgilityWriterText') ? undefined : json['dontOverrideAgilityWriterText'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (json['fromDate'] === null ? null : new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (json['toDate'] === null ? null : new Date(json['toDate'])),
        'publishedDate': !exists(json, 'publishedDate') ? undefined : (json['publishedDate'] === null ? null : new Date(json['publishedDate'])),
        'previouslyPublished': !exists(json, 'previouslyPublished') ? undefined : json['previouslyPublished'],
        'scheduledForPublished': !exists(json, 'scheduledForPublished') ? undefined : json['scheduledForPublished'],
        'germanWordsInText': !exists(json, 'germanWordsInText') ? undefined : json['germanWordsInText'],
        'sourceDataSpecs': !exists(json, 'sourceDataSpecs') ? undefined : (json['sourceDataSpecs'] === null ? null : (json['sourceDataSpecs'] as Array<any>).map(SourceDataSpecFromJSON)),
    };
}

export function SourceDataToJSON(value?: SourceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'input': value.input,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'title': value.title,
        'editedTitle': value.editedTitle,
        'type': SourceTypeToJSON(value.type),
        'url': value.url,
        'website': value.website,
        'generated': value.generated,
        'processedDatas': value.processedDatas === undefined ? undefined : (value.processedDatas === null ? null : (value.processedDatas as Array<any>).map(ProcessedDataToJSON)),
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'cmsContentId': value.cmsContentId,
        'agilityWriterText': value.agilityWriterText,
        'agilityWriterAddedDate': value.agilityWriterAddedDate === undefined ? undefined : (value.agilityWriterAddedDate === null ? null : value.agilityWriterAddedDate.toISOString()),
        'dontOverrideAgilityWriterText': value.dontOverrideAgilityWriterText,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate === null ? null : value.fromDate.toISOString()),
        'toDate': value.toDate === undefined ? undefined : (value.toDate === null ? null : value.toDate.toISOString()),
        'publishedDate': value.publishedDate === undefined ? undefined : (value.publishedDate === null ? null : value.publishedDate.toISOString()),
        'previouslyPublished': value.previouslyPublished,
        'scheduledForPublished': value.scheduledForPublished,
        'germanWordsInText': value.germanWordsInText,
        'sourceDataSpecs': value.sourceDataSpecs === undefined ? undefined : (value.sourceDataSpecs === null ? null : (value.sourceDataSpecs as Array<any>).map(SourceDataSpecToJSON)),
    };
}

